<template>
  <v-card>
    <v-card-title class="ma-0 pa-0">
      <v-toolbar>
        {{ $t("tag.sensor.title") }}
        <v-spacer></v-spacer>
        <v-btn
          @click="createNewSensorDialog = true"
          color="primary lighten-2"
          class="mr-4"
        >
          {{ $t("tag.sensor.add") }}
        </v-btn>

        <v-btn @click="updateAll" color="primary">
          {{ $t("common.updateAll") }}
        </v-btn>
      </v-toolbar>
    </v-card-title>

    <v-card-text>
      <div
        class="sensor-information"
        v-for="(sensor, i) in localCurrents"
        :key="i"
      >
        <div class="sensor-information-header">
          <div class="d-flex align-center justify-start">
            <div
              class="sensor-status"
              :class="{
                'sensor-status-ok': status(sensor) === 'ok',
                'sensor-status-error': status(sensor) === 'error',
                'sensor-status-warning': status(sensor) === 'warning',
              }"
            ></div>
            <span class="text-subtitle-1"
              ><b>{{ sensor.label }}</b> - {{ sensor.key }}</span
            >
          </div>

          <div class="d-flex align-center justify-end">
            <v-btn
                @click="() => {createNewValueDialog = true; createNewSensorKey = sensor.key;}"
              color="success lighten-2"
              class="mr-4"
              fab
              x-small
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              @click="deleteSensorConfirm(sensor)"
              color="error"
              class="mr-4"
              fab
              outlined
              x-small
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="sensor-information-content">
          <v-switch
            v-model="sensor.publish"
            :label="$t('tagdata.fields.publishKey')"
            class="mr-4"
          ></v-switch>
          <v-text-field
            v-model="sensor.label"
            :label="$t('tagdata.fields.label')"
            class="mx-4"
          />
          <v-btn
            @click="updateSensor(sensor)"
            color="primary"
            class="mr-4"
            text
            outlined
          >
            {{ $t("common.update") }}
          </v-btn>
        </div>
      </div>
    </v-card-text>

    <v-dialog v-model="createNewSensorDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("tag.sensor.add") }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="createNewSensorKey"
            :label="$t('tagdata.fields.key')"
            class="mx-4"
          />
          <v-text-field
            v-model="createNewSensorValue"
            :label="$t('tagdata.fields.value')"
            class="mx-4"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createNewSensorDialog = false" text>
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn @click="createNewSensor" color="primary" text>
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createNewValueDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("module.functionButtonTypes.input")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="createNewSensorValue"
            :label="$t('tagdata.fields.value')"
            class="mx-4"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createNewValueDialog = false" text>
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn @click="createNewValue" color="primary" text>
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-dialog
      :dialog="confirmDelete"
      v-on:confirm-delete="deleteSensor(selectedSensor)"
      v-on:cancel-dialog="confirmDelete = false"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeleteDialog from "@/components/common/DeleteDialog";

export default {
  name: "SensorTab",

  components: {
    DeleteDialog,
  },

  props: {
    value: Object,
    default: () => {},
  },

  data() {
    return {
      confirmDelete: false,
      createNewValueDialog: false,
      createNewSensorDialog: false,
      createNewSensorKey: "",
      createNewSensorValue: 0,
      localCurrents: [],
      selectedSensor: undefined,
    };
  },

  methods: {
    ...mapActions("tagData", ["getCurrentsForTag"]),
    ...mapActions("tag", ["deleteTagKey", "updateTagKey", "addTagKey", "setTagData"]),
    ...mapActions("alert", ["success", "error"]),

    status(sensor) {
      if (!sensor.updatedAt) return "error";
      if (!this.timeWithinHours(sensor.updatedAt, 12)) return "warning";

      return "ok";
    },

    async updateAll() {
      for (let sensor of this.localCurrents) {
        await this.updateSensor(sensor);
      }
    },

    async updateSensor(sensor) {
      let payload = {
        publish: sensor.publish,
        deveui: sensor.tagDeveui,
        key: sensor.key,
        label: sensor.label,
      };

      let result = await this.updateTagKey({ payload: payload });
      if (result) this.success(this.$t("common.updated"));
    },

    deleteSensorConfirm(sensor) {
      this.selectedSensor = sensor;
      this.confirmDelete = true;
    },

    async deleteSensor(sensor) {
      let result = await this.deleteTagKey({
        tagId: sensor.tagDeveui,
        key: sensor.key,
      });

      if (result) this.success(this.$t("common.deleted"));
      this.confirmDelete = false;
      this.localCurrents = this.localCurrents.filter(
        (s) => s.key !== sensor.key
      ); // Filter away the key that is no longer with us instead of fetching again
    },

    async createNewSensor() {
      let payload = {
        publish: false,
        deveui: this.value.deveui,
        key: this.createNewSensorKey,
        label: this.createNewSensorKey,
        virtual: true,
        value: this.createNewSensorValue,
      };

      let result = await this.addTagKey({ payload: payload });
      if (result) 
      {
        this.success(this.$t("common.success"));
        this.localCurrents.push(payload);
      }

      this.createNewSensorDialog = false;
    },

    async createNewValue() {
      let payload = { key: this.createNewSensorKey, value: this.createNewSensorValue};
      let result = await this.setTagData({
        tagId: this.value.deveui,
        payload: payload,
      });

      if (result) this.success(this.$t("common.success"));

      this.createNewValueDialog = false;
    },
  },

  computed: {
    ...mapGetters("tagData", ["currents"]),
  },

  async created() {
    await this.getCurrentsForTag(this.value.deveui);
    this.localCurrents = this.currents;
  },
};
</script>

<style scoped>
.sensor-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sensor-information-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 10px 10px 0px 0px;
}

.sensor-information-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  padding-top: 0;
  border-radius: 0 0 10px 10px;
}

.sensor-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}

.sensor-status-ok {
  background-color: green;
}

.sensor-status-error {
  background-color: red;
}

.sensor-status-warning {
  background-color: yellow;
}
</style>
