<template>
  <div>
    <v-textarea
      v-model="localComment"
      outlined
      :placeholder="$t('tag.log.journal.commentPlaceholder')"
      rows="2"
      counter
      maxlength="500"
      :label="$t('tag.log.journal.comment')"
      @input="handleComment"
    ></v-textarea>

    <v-btn
      text
      rounded
      color="primary"
      @click="triggerFileInput"
      class="mt-2 mb-4 align-self-start"
      style="font-size: 0.7rem; margin-bottom: 0"
    >
      <v-icon left class="rotate-icon">mdi-attachment</v-icon>
      {{ $t("tag.log.journal.chooseFile") }}
    </v-btn>

    <input
      type="file"
      ref="fileInput"
      :accept="acceptFileTypes"
      multiple
      style="display: none"
      @change="handleFileInput"
    />

    <v-row class="mt-2">
      <v-col
        v-for="(file, index) in localPreviewFiles"
        :key="index"
        cols="auto"
        class="position-relative d-flex flex-column align-items-center"
      >
        <!-- File Name -->
        <div class="file-name">{{ file.name }}</div>

        <!-- Preview or Icon -->
        <div class="file-container">
          <v-img
            v-if="file.type.includes('image')"
            :src="file.src"
            width="100"
            height="100"
          ></v-img>
          <v-card
            v-else-if="file.type.includes('pdf')"
            width="100"
            height="100"
          >
            <embed
              :src="file.src"
              type="application/pdf"
              width="100"
              height="100"
            />
          </v-card>
          <v-icon
            v-else
            :class="`file-icon mdi mdi-${getFileIcon(file.extension)}`"
            width="100"
            height="100"
            :style="{ color: getFileIconColor(file.extension) }"
          ></v-icon>

          <!-- Remove Button -->
          <v-btn
            icon
            x-small
            color="white"
            @click="removeFile(index)"
            class="file-remove-btn"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>

        <!-- File Type Label -->
        <div class="file-label">
          {{ file.extension.toUpperCase() }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "JournalEntryForm",
  props: {
    comment: {
      type: String,
      default: "",
    },
    previewFiles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      localComment: this.comment,
      localPreviewFiles: this.previewFiles,
    };
  },

  computed: {
    acceptFileTypes() {
      const imageTypes = ["bmp", "jpeg", "jpg", "gif", "tiff", "png"];
      const documentTypes = ["txt", "lic", "csv", "doc", "docx", "xls", "xlsx"];
      const fileTypes = [
        ...imageTypes.map((type) => `.${type}`),
        ...documentTypes.map((type) => `.${type}`),
        "application/pdf",
      ];
      return fileTypes.join(",");
    },
  },

  methods: {
    handleComment(value) {
      this.$emit("update:comment", value);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileInput(event) {
      this.$emit("file-input", event);
    },
    removeFile(index) {
      this.$emit("remove-file", index);
    },

    getFileIcon(extension) {
      switch (extension) {
        case "txt":
          return "file-document-outline";
        case "csv":
          return "file-delimited-outline";
        case "doc":
        case "docx":
          return "file-word-outline";
        case "xls":
        case "xlsx":
          return "file-excel-outline";
        default:
          return "file-outline";
      }
    },

    // Method to get the appropriate color based on file extension
    getFileIconColor(extension) {
      switch (extension) {
        case "txt":
          return "#4A90E2"; // Blue for text files
        case "csv":
          return "#4CAF50"; // Green for CSV files
        case "doc":
        case "docx":
          return "#0078D4"; // Dark blue for Word files
        case "xls":
        case "xlsx":
          return "#4CAF50"; // Green for Excel files
        case "pdf":
          return "#D32F2F"; // Red for PDFs
        default:
          return "#9E9E9E"; // Gray for unknown file types
      }
    },
  },

  watch: {
    comment(newVal) {
      this.localComment = newVal;
    },

    previewFiles(newVal) {
      this.localPreviewFiles = newVal;
    },
  },
};
</script>

<style scoped>
.rotate-icon {
  transform: rotate(90deg);
}

.position-relative {
  position: relative;
}

.file-name {
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.file-remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(190, 177, 177, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: none;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.file-container:hover .file-remove-btn {
  display: flex;
}

.file-remove-btn:hover {
  background-color: red;
}

.file-icon {
  font-size: 48px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.file-label {
  font-size: 12px;
  text-align: center;
}
</style>
